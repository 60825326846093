@charset "UTF-8";
.account-page {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(255, 38, 74, 0.75);
}
.account-page h1.sectionTitle {
  display: block;
  font-size: 45px;
  font-weight: 400;
  line-height: 0.8;
  letter-spacing: 8px;
  margin-bottom: 80px;
  color: #000;
}
.account-page .input-form {
  width: 100%;
  max-width: 550px;
  position: relative;
  height: 50px;
  background: rgba(204, 204, 204, 0.4);
  border-radius: 0;
  border: 1px solid #CCC;
  margin-bottom: 20px;
  font-size: 14px;
  color: #333;
  font-weight: 300;
  letter-spacing: 3px;
  padding-left: 19px;
}
.account-page button.submit {
  width: fit-content;
  height: 50px;
  padding: 0 1.5rem;
  background: #ccc;
  border-radius: 4px;
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  border: none;
  transition: all ease 400ms;
  -moz-transition: all ease 400ms;
  -webkit-transition: all ease 400ms;
  -ms-transition: all ease 400ms;
  -o-transition: all ease 400ms;
}
.account-page button.submita:hover {
  background: #2c3da7;
  color: #fff;
}
.account-page .btn-recover {
  padding-left: 33px;
  line-height: 1.5;
  font-size: 14px;
  color: #000;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.account-page .btn-remember {
  color: #999999;
  font-size: 12px;
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
  padding-left: 10px;
  letter-spacing: 2px;
  line-height: 3rem;
  margin: 0 0 15px 0;
}
.account-page .phrase {
  color: #999999;
  font-size: 10px;
  font-weight: 300;
  text-transform: uppercase;
  position: relative;
  padding-left: 10px;
  letter-spacing: 2px;
  line-height: 3rem;
  margin: 0 0 15px 0;
}
@media only screen and (min-width: 425px) and (max-width: 767.98px) {
  .account-page .input-form {
    max-width: 320px;
  }
  .account-page .group-actions {
    display: flex;
    flex-direction: column;
  }
  .account-page .btn-recover {
    padding-left: 0;
    padding-top: 33px;
  }
}

.article-page {
  padding-top: 108px;
  display: block;
  margin: auto;
  max-width: 70%;
}
.article-page h1.sectionTitle {
  display: block;
  font-size: 45px;
  font-weight: 400;
  line-height: 0.8;
  letter-spacing: 8px;
  margin: 80px 0 40px 0;
  color: #000;
  text-align: center;
}
.article-page h6 {
  font-size: 24px;
  line-height: 34px;
  color: black;
  letter-spacing: 0.6px;
  font-weight: 400;
}
.article-page .description {
  font-size: 16px;
  line-height: 28px;
  color: #757575;
  letter-spacing: 0.6px;
  font-weight: 300;
}

/** Título de cada categoria com a presença de img **/
.both-theme {
  font-size: 80px;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -ms-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  font-family: "Oswald", sans-serif;
  line-height: 1.5;
  font-style: normal;
  text-transform: uppercase;
  text-align: left;
  font-weight: 700;
  letter-spacing: -0.05em;
  margin: 12px 0 -1px 22px;
}

@media all and (min-width: 1920px) {
  .both-theme {
    font-size: 100px;
    margin: 9px 0 -2px 22px;
  }
}
@media only screen and (max-width: 1300px) {
  .both-theme {
    font-size: 60px;
    margin: 13px 0 -1px 15px;
  }
}
/*Página do sobre nós*/
@media (min-width: 1920px) {
  #about-carousel .post-block-correction {
    margin-bottom: 6rem;
  }
}
.icon-effect .description-media {
  background: #ff264a;
  width: fit-content;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

/**Nav of Modal Carousel*/
#modal-carousel video {
  max-width: 100%;
}

#newsModal-0 .iframe {
  border: 0;
}

.owl-nav-custom-modal {
  display: flex;
  gap: 1rem;
}
.owl-nav-custom-modal .owl-prev, .owl-nav-custom-modal .owl-next {
  left: 0;
  position: relative;
}

.contact-form .contactDanger, .contact-form .contactSuccess {
  position: fixed;
  bottom: 1rem;
  width: 100%;
  max-width: 460px;
  z-index: 100;
  right: 6rem;
}

* {
  scrollbar-width: 6px;
  scrollbar-color: rgba(255, 38, 74, 0.5) transparent;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 6px;
  height: 3px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(255, 38, 74, 0.5);
  border-radius: 10px;
  border: 1px solid transparent;
}

@media all and (max-width: 767.98px) {
  .just-mobile {
    display: block !important;
  }
}
@media all and (min-width: 768px) {
  .just-mobile {
    display: none !important;
  }

  .bottom-credits-right {
    bottom: 20px;
    position: absolute;
    left: 6rem;
    z-index: 4;
    font-family: "Oswald", sans-serif;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    padding: 0;
    text-align: left;
    letter-spacing: 0.05em;
    color: #ff264a;
  }
  .bottom-credits-right a {
    margin-right: 3rem;
  }
}